import * as React from "react";
import Accordion from "../components/Accordion";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import FavGameCard from "../components/FavGameCard";

const IndexPage = ({ data }) => {
  console.log(data.allSanityGames.nodes);
  console.log(data.allSanityCharacters.nodes);
  return (
    <section className="container">
      <div
        id="heading"
        className="my-16 flex flex-col justify-center items-center"
      >
        <h1 className="text-center leading-[48px]">About Me</h1>
        <h2 className="text-center tracking-widest text-secondary">
          Get To Know More About Me Through This Website
        </h2>
      </div>
      <div className="flex flex-col gap-y-4">
        <Accordion
          title="My Favourite Games"
          accordion={data.allSanityGames.nodes.map(game => (
            <div key={game.name} className="mb-3">
              <Accordion
                isChildren
                title={game.name}
                content={
                  <FavGameCard
                    image={game.logoImage.asset.gatsbyImageData}
                    alt={game.name}
                  />
                }
              />
            </div>
          ))}
        />
        <Accordion
          title="My Favourite Food"
          content="If you're interested in predesigned component examples using Headless UI and Tailwind CSS, check out Tailwind UI — a collection of beautifully designed and expertly crafted components built by us.
        It's a great way to support our work on open-source projects like this and makes it possible for us to improve them and keep them well-maintained."
        />
        <Accordion
          title="My Favourite Drinks"
          content="If you're interested in predesigned component examples using Headless UI and Tailwind CSS, check out Tailwind UI — a collection of beautifully designed and expertly crafted components built by us.
        It's a great way to support our work on open-source projects like this and makes it possible for us to improve them and keep them well-maintained."
        />
        <Accordion
          title="My Favourite Apps"
          content="If you're interested in predesigned component examples using Headless UI and Tailwind CSS, check out Tailwind UI — a collection of beautifully designed and expertly crafted components built by us.
        It's a great way to support our work on open-source projects like this and makes it possible for us to improve them and keep them well-maintained."
        />
        <Accordion
          title="My Favourite Websites"
          content="If you're interested in predesigned component examples using Headless UI and Tailwind CSS, check out Tailwind UI — a collection of beautifully designed and expertly crafted components built by us.
        It's a great way to support our work on open-source projects like this and makes it possible for us to improve them and keep them well-maintained."
        />
        <Accordion
          title="My Social Accounts"
          content="If you're interested in predesigned component examples using Headless UI and Tailwind CSS, check out Tailwind UI — a collection of beautifully designed and expertly crafted components built by us.
        It's a great way to support our work on open-source projects like this and makes it possible for us to improve them and keep them well-maintained."
        />
      </div>
      <div className="text-center mt-20 text-text/70">
        @2022 - Pyae Sone Min Naing
      </div>
    </section>
  );
};

export default IndexPage;

export const query = graphql`
  query AllDataQuery {
    allSanityGames {
      nodes {
        name
        type {
          name
        }
        logoImage {
          asset {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
        reason
        officialWebsite
      }
    }
    allSanityCharacters {
      nodes {
        name
        role {
          name
        }
        brief
        image {
          asset {
            gatsbyImageData
          }
        }
        game {
          name
        }
      }
    }
  }
`;
