import React from "react";
import { useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

const Accordion = ({ title, content, accordion, isChildren }) => {
  const [isOpen, toggle] = useState(false);

  return (
    <div className="w-full max-w-[960px] mx-auto">
      <button
        className={`flex  items-center justify-between gap-x-6 rounded-lg ${
          isOpen && "rounded-b-none"
        } ${
          isChildren
            ? "bg-background/40 hover:bg-background/40 text-[#40C9A2] w-[75%]"
            : "bg-[#40c9a2]/70 hover:bg-[#40c9a2]/60 text-secondary w-full"
        } px-8 py-4`}
        onClick={() => toggle(!isOpen)}
      >
        <h2
          className={`font-sora text-left ${
            isChildren ? "text-[20px]" : "text-[22px]"
          } tracking-wide ${
            isChildren ? "text-[#40C9A2]" : "text-secondary"
          } font-normal`}
        >
          {title}
        </h2>
        {isOpen ? <FaChevronUp size={20} /> : <FaChevronDown size={20} />}
      </button>
      <div
        className={`${isOpen && "px-8 py-2"} ${
          isChildren ? "bg-background/40 px-12 w-[75%]" : "bg-[#40c9a2]/70"
        } text-secondary rounded-b-lg`}
      >
        <div
          className={`${
            isOpen ? "h-auto" : "h-0 overflow-hidden"
          } leading-relaxed text-base font-light tracking-wider font-sora mb-2`}
        >
          <p>{content}</p>
          {accordion}
        </div>
      </div>
    </div>
  );
};

export default Accordion;
