import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

const FavGameCard = ({ image, alt }) => {
  return (
    <div className="w-full h-fit">
      <div className="flex flex-row justify-center items-center gap-x-8 mb-4">
        <GatsbyImage image={image} alt={alt} width="200px" height="200px" />
      </div>
      <div>
        <p>
          When not climbing the ranks, you'll be able to learn from the best.
          The Dota Pro Circuit features ultra-high-level Dota 2 competition
          streaming regularly in the game client, on Twitch and Steam.TV.
          Culminating in the largest e-sports championship in the world, The
          International, professional Dota 2 is an event not to be missed.
        </p>
      </div>
    </div>
  );
};

export default FavGameCard;
